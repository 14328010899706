import React, { useRef, useCallback, useMemo } from 'react'
import { EMPTY_FUNCTION, STATUS_COMPLETED } from '../../src/config/constants'

export const ActionContext = React.createContext({
  isCurrentActionAuto: true,
  findNextPage: EMPTY_FUNCTION,
  state: []
})

const ActionProvider = (props) => {
  const actionState = useRef([])
  const actionsToExecute = useRef(['languageCountrySelector'])

  const findNextAction = useCallback(async () => {
    let nextAction = actionsToExecute.current.shift()

    if (!nextAction) return STATUS_COMPLETED

    return nextAction
  }, [])

  const findNextPage = useCallback(
    async (result) => {
      if (result) {
        actionState.current.push(result)
      }
      return `/${await findNextAction()}`
    },
    [findNextAction]
  )

  const state = useMemo(
    () => ({
      findNextPage,
      isCurrentActionAuto: true,
      state: actionState.current
    }),
    [findNextPage]
  )

  return (
    <ActionContext.Provider value={state}>
      {props.children}
    </ActionContext.Provider>
  )
}

export default ActionProvider
